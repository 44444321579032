import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Logo from '@/components/Logo'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useMenu } from '@/bus/menu'
import { routes } from '@/config'
import DefaultHeader from '@/layouts/Header'
import styles from '@/layouts/Main/Main.module.scss'

const Header: FC = () => {
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  const { t } = useTranslation()
  const { toggleShown } = useMenu()
  return (
    <DefaultHeader
      className={styles['main__header']}
      before={<Logo />}
      after={
        <>
          {isMiddleScreen && (
            <Button
              title={t('forms:buttons.dontHaveAnAccount') as string}
              as={'span'}
              href={routes.REGISTER}
              variant={'link'}
              onClick={() => toggleShown({ shown: false })}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.dontHaveAnAccount')
                }}
              />
            </Button>
          )}
          {isMiddleScreen && (
            <Button
              title={t('forms:buttons.createAccount') as string}
              onClick={() => toggleShown({ shown: false })}
              as={Link}
              href={routes.REGISTER}
              before={<Icon name={'user/24'} />}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.createAccount')
                }}
              />
            </Button>
          )}

          <Button
            title={t('forms:buttons.goHome') as string}
            as={Link}
            href={routes.HOME}
            variant={'light'}
            size={'icon'}
          >
            <Icon name={'cross/32'} color={'#1E2F4B'} />
          </Button>
        </>
      }
    />
  )
}

export default Header
