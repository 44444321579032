import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import React from 'react'

import Heading from '@/components/Heading'

import Link from '../../components/Link'

import styles from './error404.module.scss'
import { routes } from '@/config'
import Footer from '@/contents/Error404/Footer'
import Header from '@/contents/Error404/Header'

type Error404Type = React.FunctionComponent & {
  Header: React.FC
  Footer: React.FC
}
const Error404: Error404Type = () => {
  const { t } = useTranslation()

  return (
    <>
      <NextSeo title={'404'} />
      <div className={styles['fourOhFour']}>
        <div className={styles['fourOhFour__content']}>
          <div className={styles['fourOhFour__summary']}>
            <Heading className={styles['fourOhFour__heading']} level={1}>
              404
            </Heading>
            <div
              className={styles['fourOhFour__about']}
              dangerouslySetInnerHTML={{ __html: t('error404:about') }}
            />
            <div className={styles['fourOhFour__back']}>
              <span
                dangerouslySetInnerHTML={{ __html: t('error404:you_can') }}
              />{' '}
              <Link
                href={routes.HOME}
                title={t('forms:buttons.goHome') as string}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: t('error404:main_page') }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Error404.Header = Header
Error404.Footer = Footer
export default Error404
