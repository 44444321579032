import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import Button from '@/components/Button'
import Carousel from '@/components/Carousel'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

export const AboutMain: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()
  const { isAuthorized } = useUser()

  useEffect(() => {
    setAnimationStart(true)
  }, [])

  return (
    <section
      className={clsx(styles['section'], className, {
        [styles['section_animation-start']]: animationStart
      })}
    >
      <div className={styles['section__content']}>
        <div className={styles['section__col']}>
          <Heading className={styles['section__heading']} level={2}>
            {t('about:heading')}
          </Heading>
          <div
            className={styles['section__summary']}
            dangerouslySetInnerHTML={{ __html: t('about:summary') }}
          />
          <div className={styles['section__auth-links']}>
            {isAuthorized ? (
              <Button
                href={{ pathname: routes.SERVICES, spa: false }}
                as={Link}
                size={'l'}
                className={styles['section__button-link']}
                before={<Icon name="arrows/right/28" />}
              >
                {t('forms:buttons.services')}
              </Button>
            ) : (
              <>
                <Button
                  title={t('forms:buttons.signup') as string}
                  href={routes.REGISTER}
                  as={Link}
                  size={'l'}
                  className={styles['section__button-link']}
                  before={<Icon name="arrows/right/28" />}
                >
                  {t('forms:buttons.signup')}
                </Button>
                <Button
                  title={t('forms:buttons.signin') as string}
                  href={routes.LOGIN}
                  as={Link}
                  className={styles['section__button-link']}
                  size={'l'}
                  before={<Icon name="user/28" />}
                  variant={'ghost'}
                >
                  {t('forms:buttons.signin')}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={styles['section__col']}>
          <Carousel />
        </div>
      </div>
    </section>
  )
}
