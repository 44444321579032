import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Logo from '@/components/Logo'

import useMediaQuery from '@/hooks/useMediaQuery'

import { useMenu } from '@/bus/menu'
import DefaultHeader from '@/layouts/Header'
import styles from '@/layouts/Main/Main.module.scss'

const Header: FC = () => {
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  const { t } = useTranslation()
  const { toggleShown } = useMenu()
  return (
    <DefaultHeader
      className={styles['main__header']}
      before={<Logo />}
      after={<></>}
    />
  )
}

export default Header
