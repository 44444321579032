import dynamic from 'next/dynamic'
import { FC } from 'react'
import LazyLoad from 'react-lazy-load'

import styles from './Home.module.scss'
import { AboutMain } from '@/sections/AboutMain'

const Partners = dynamic(
  () => import('@/sections/Partners').then(({ Partners }) => Partners),
  {
    ssr: false
  }
)

const Platform = dynamic(
  () => import('@/sections/Platform').then(({ Platform }) => Platform),
  {
    ssr: false
  }
)
const Unlimited = dynamic(
  () => import('@/sections/Unlimited').then(({ Unlimited }) => Unlimited),
  {
    ssr: false
  }
)
const Billing = dynamic(
  () => import('@/sections/Billing').then(({ Billing }) => Billing),
  {
    ssr: false
  }
)
const Services = dynamic(
  () => import('@/sections/Services').then(({ Services }) => Services),
  {
    ssr: false
  }
)

const Cpa = dynamic(() => import('@/sections/Cpa').then(({ Cpa }) => Cpa), {
  ssr: false
})

export const Home: FC = () => {
  return (
    <>
      <AboutMain className={styles['home__about-main']} />
      <LazyLoad className={styles.home__partners}>
        <Partners />
      </LazyLoad>
      <div id={'add_networks'}>
        <LazyLoad className={styles.home__platform}>
          <Platform />
        </LazyLoad>
      </div>
      <div id={'rent_ads_account'}>
        <LazyLoad className={styles.home__unlimited}>
          <Unlimited />
        </LazyLoad>
      </div>
      <div id={'deposit_methods'}>
        <LazyLoad className={styles.home__billing}>
          <Billing />
        </LazyLoad>
      </div>
      <div id={'agency'}>
        <LazyLoad className={styles.home__services}>
          <Services />
        </LazyLoad>
      </div>

      <div id={'cpa_network'}>
        <LazyLoad className={styles.home__cpa}>
          <Cpa />
        </LazyLoad>
      </div>
    </>
  )
}
