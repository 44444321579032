import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide'
import clsx from 'clsx'
import { FC } from 'react'

import Picture from '@/components/Picture'

import styles from './Carousel.module.scss'

const OPTIONS: Options = {
  arrows: false,
  type: 'loop',
  autoplay: true,
  interval: 3000,
  classes: {
    pagination: clsx('splide__pagination', styles['carousel__pagination']),
    page: clsx('splide__pagination__page', styles['carousel__page'])
  }
}

const SLIDES = [
  {
    id: 'automate',
    image: 'pic1.png',
    heading: 'Automate the management of advertising traffic'
  },
  {
    id: 'cashback',
    image: 'pic2.png',
    heading: 'Cashback on all advertising for partners'
  },
  {
    id: 'balance',
    image: 'pic3.png',
    heading: 'Single balance and convenient replenishment methods'
  },
  {
    id: 'result',
    image: 'pic4.png',
    heading: 'Reports to control the advertising budget and results'
  }
]

const Carousel: FC = () => {
  return (
    <Splide options={OPTIONS} hasTrack={false} className={styles['carousel']}>
      <SplideTrack className={styles['carousel__track']}>
        {SLIDES.map(({ id, image, heading }) => (
          <SplideSlide
            key={id}
            className={clsx(
              styles['carousel__slide'],
              styles[`carousel__slide_${id}`]
            )}
          >
            <div className={styles['carousel__heading']}>{heading}</div>
            <div className={styles['carousel__fig']}>
              <Picture
                src={`/images/carousel/${image}`}
                alt={''}
                className={styles['carousel__image']}
              />
            </div>
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  )
}
export default Carousel
