import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import RegisterForm from '@/components/Forms/RegisterForm'
import Heading from '@/components/Heading'

import styles from './Register.module.scss'

export const Signup: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles['register']}>
      <Heading className={styles['register__heading']} level={3}>
        {t('signup:heading')}
      </Heading>

      <RegisterForm />
    </div>
  )
}
