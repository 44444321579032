import { FC, Fragment } from 'react'

type SrcProp = {
  src: string
  type: string
}
interface PictureProps {
  src: string | SrcProp[]
  alt?: string
  className?: string
}

const Picture: FC<PictureProps> = ({ className = '', src = '', alt = '' }) => {
  if (typeof (src as string) === 'string') {
    const url = `${src}?v=${process.env.VERSION}`

    return (
      <Fragment>
        <source srcSet={`${url}, ${url.replace('.', 'x2.')} 2x`} />
        <img className={className} src={url} alt={alt} />
      </Fragment>
    )
  }

  return (
    <Fragment>
      {(src as SrcProp[]).map(({ src, type }) => {
        const url = `${src}?v=${process.env.VERSION}`

        return (
          <Fragment key={type}>
            <source
              srcSet={`${url}, ${url?.replace('.', 'x2.')} 2x`}
              type={type}
            />
            {type === 'image/jpeg' && (
              <img className={className} src={url as string} alt={alt} />
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default Picture
